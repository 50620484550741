import React from 'react';
import Section from '../../../layout/Section/Section';
import * as styles from './release-note-header.module.scss';
import { useTranslation } from 'react-i18next';


export function ReleaseNoteHeader() {
  const { t } = useTranslation( 'common' );

  return (
    <Section className={ styles.section } isNarrow as={ 'header' }>
      <h1 className={ styles.heading }>Release Notes</h1>
      <p className={ styles.desc }>{ t( 'releaseNotesDesc' ) }</p>
      <div className={ styles.grad01 } />
    </Section>
  );
}