import React from 'react';
import Section from '../../../layout/Section/Section';
import { ReleaseNoteItem } from '../ReleaseNoteItem/ReleaseNoteItem';
import * as styles from './release-note-contents.module.scss';
import * as pageStyles from '../../Page/Page/page.module.scss';
import { classNames } from '../../../../utils';


export function ReleaseNoteContents( { data } ) {
  return (
    <Section
      id="release-notes"
      className={ classNames( pageStyles.pageBody, pageStyles.pageBodyDocument, styles.section ) }
    >
      { data.allReleaseNote.edges.map( ( edge, index ) => (
        <ReleaseNoteItem
          key={ edge.node.id }
          node={ edge.node }
          isLatest={ index === 0 }
        />
      ) ) }
    </Section>
  );
}