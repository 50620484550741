import React from 'react';
import Diamond from '../../../../assets/images/svg/diamond.svg';
import * as styles from './release-note-item.module.scss';
import { Button } from '../../../components/Button/Button';


export function ReleaseNoteItem( { node } ) {
  const { published_at: publishedAt } = node;
  const date = new Intl.DateTimeFormat( 'en-US', {
    dateStyle: 'long',
  } ).format( new Date( publishedAt ) )

  return (
    <section className={ styles.section }>
      <header className={ styles.header }>
        <h2 className={ styles.heading }>{ node.name }</h2>
        <time className={ styles.date } dateTime={ publishedAt }>{ date }</time>
      </header>

      <div className={ styles.separator }>
        <Diamond className={ styles.shape } />
        <div className={ styles.line } />
      </div>

      <div className={ styles.content }>
        <div dangerouslySetInnerHTML={ { __html: node.html } } />
        <Button
          isTertiary
          isTiny
          href={ node.html_url }
          newTab
          icon="arrow"
        >
          Get Package
        </Button>
      </div>
    </section>
  );
}