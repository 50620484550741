import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/Layout/Layout';
import { SEO } from '../components/seo/SEO/SEO';
import { ReleaseNoteHeader, ReleaseNoteContents } from '../components/scenes/ReleaseNotes';


export default function ReleaseNotes( { data } ) {
  return (
    <Layout isSecondaryNav>
      <ReleaseNoteHeader />
      <ReleaseNoteContents data={ data } />
    </Layout>
  );
}

export function Head() {
  return (
    <SEO
      title={ 'Release Notes' }
      description={ 'The Splide history of additions and improvements.' }
    />
  );
}

export const query = graphql`
  query {
    allReleaseNote(filter: {tag_name: {glob: "v4*"}}) {
      edges {
        node {
          body
          name
          tag_name
          id
          published_at
          html
          html_url
        }
      }
    }
  }
`;

